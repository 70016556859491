// section styles
section {
	margin-bottom: 30px;

	> *:first-child {
		margin-top: 30px;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $dnBlue;
	}
}

@media only screen and (min-width: $bpTablet){
	section {
		>*:first-child {
			margin-top: 0;
		}
	}
}
