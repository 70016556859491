// header-search styles
.site-header__search {
	font-size: calc-em(12px, 11px);
	display: inline-block;
	width: 100%;
	max-width: 260px;
	position: relative;
	margin-bottom: 20px;

	input {
		width: 100%;
		padding: 8px 45px 8px 0;
		border: none;
		border-bottom: 1px solid $dnBorder;
		color: $dnNavTextBlack;
		background-color: $dnOffWhiteRGBT;
		outline: 0;
		transition: all 0.3s;

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $dnLine;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: $dnLine;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: $dnLine;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: $dnLine;
		}

		&:focus {
			padding-left: 8px;
		}
	}

	button {
		position: absolute;
		background-color: transparent;
		border: none;
		padding: 0;
		margin: 0;
		right: 0;
		top: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;
		outline: 0;

		&:hover {
			i {
				color: $dnBlue;
			}
		}
	}

	i {
		color: $dnSearchIconBlack;
		transition: all 0.3s;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-header__search {
		display: block;
		font-size: calc-em(22px, 12px);
		max-width: 400px;
		line-height: 1;
		margin: 0 auto 80px;

		button {
			width: 42px;
			height: 42px;
		}

		i {
			font-size: calc-em(18px, 22px);
		}
	}
}
