// header-language styles
ul.site-header__links--language {
	float: right;

	li {
		float: left;

		&.active a {
			color: $dnBlue;
			// color: $dnBlue !important;
		}

		&:last-child a {
			padding-right: 0;

			&:after {
				display: none;
			}
		}
	}

	a {
		color: $dnLanguageSelector;
		display: inline-block;
		padding-right: 8px;
		text-decoration: none;
		font-weight: 700;

		&:after {
			content: '|';
			display: inline-block;
			position: relative;
			vertical-align: middle;
			margin-top: -2px;
			margin-left: 9px;
		}

		&:hover {
			color: $dnBlue;
			// color: $dnBlue !important;
			text-decoration: underline;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	ul.site-header__links--language {
		float: left;
		display: inline-block;
		position: relative;
		z-index: 8;
		// right: 520px;
		// padding-right: 40px;
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease-in;
	}
}

