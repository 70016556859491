// profile-qualifications styles
.profile-qualifications {
	&__header {
		h3 {
			color: $dnBlue;
			font-size: calc-em(19px);
			line-height: 1;
			margin: 0 0 16px;
		}
	}

	&__content {
		ul {
			@extend %list-unstyled;
			@extend %clearfix;
			padding: 0 10px;
		}

		li {
			font-size: calc-em(13px);
			line-height: 18px;
			margin: 0 0 13px;
			padding-left: 23px;
			position: relative;

			&:before {
				content: '';
				width: 8px;
				height: 8px;
				border-radius: 50%;
				border: 2px solid $dnBlue;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 5px;
			}

			&:last-child {
				margin: 0 0 10px;
			}
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.profile__details--practice-areas {
		.row {
			margin: 0;
		}

		li {
			padding: 0;
		}
	}
}

@media only screen and (min-width: $bpDesktop), print{
	.profile-qualifications {
		margin-bottom: 40px;

		&__header {
			h3 {
				font-size: calc-em(26px);
				margin: 0 0 30px;
			}
		}

		&__content {
			li {
				font-size: calc-em(16px);
				line-height: 26px;
				padding-right: 65px;

				&:before {
					width: 11px;
					height: 11px;
					position: absolute;
					top: 7px;
				}
			}
		}
	}
}

@media print {
	.container {
		padding: 0 20px;
	}
}
