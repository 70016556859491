// home-testimonial-item styles
.home-testimonial-item {
	letter-spacing: 1px;
	font-size: calc-em(12px);
	line-height: 16px;
	margin: 0 0 20px;
}

@media only screen and (min-width: $bpTablet) {
	.home-testimonial-item {
		font-size: calc-em(13px);
		line-height: 19px;
	}
}
