//functions

//font size
@function calc-em($target, $context: $base-fontsize) {
    @return $target / $context * 1em;
}

@function calc-percent($target, $total, $percent) {
    @return $total / $target * $percent;
}

//mixins

@mixin visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px !important;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px !important;
}

@mixin border-radius($radius) {
       -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
            border-radius: $radius;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
       -moz-transform: rotate($deg);
        -ms-transform: rotate($deg);
         -o-transform: rotate($deg);
            transform: rotate($deg);
}

@mixin transform($param) {
    -webkit-transform: $param;
       -moz-transform: $param;
        -ms-transform: $param;
         -o-transform: $param;
            transform: $param;
}

@mixin vertical-middle($height) {
    height: $height;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
    }
}

@mixin vertical-middle-child {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

@mixin justify {
    text-align: justify;

    &:after {
        content: '';
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0;
    }
}

@mixin aspect-ratio($x, $y, $z) {
    position: relative;
    padding-top: $y / $x * $z;
}

@mixin absolute-center {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@mixin center-block($width) {
    margin-left: auto;
    margin-right: auto;
    width: $width;
}

@mixin circle-image {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}

@mixin normalise-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin background-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

@mixin image-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}

@mixin clearfix {
    &:before, &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin transition($type, $duration, $easing: linear) {
           transition-property: unquote($type);
           transition-duration: $duration;
    transition-timing-function: $easing;
    /* Webkit */
    -webkit-transition-timing-function: $easing;
           -webkit-transition-property: unquote($type);
           -webkit-transition-duration: $duration;
           -webkit-backface-visibility: hidden;
}

// @mixin box-shadow($hor, $ver, $blur, $spread, $color) {
//     box-shadow: $hor $ver $blur $spread $color;
// }

// @mixin icomoon($glyph) {
//     @extend %icomoon;
//     content: $glyph;
// }

@mixin icomoon($glyph, $color: inherit) {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: $glyph;
    color: $color;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin mobile {
  @media (max-width: $bpTablet - 1) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $bpTablet) and (max-width: $bpDesktop - 1) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $bpDesktop) {
    @content;
  }
}

@mixin lgDesktop {
  @media (min-width: $bpLgDesktop) {
    @content;
  }
}

// @mixin transition($values...) {
//     $transitions: ();
//     @each $declaration in $values {
//         $prop: nth($declaration, 1);
//         $prop-opts: ();
//         $length: length($declaration);
//         @for $i from 2 through $length {
//             $prop-opts: append($prop-opts, nth($declaration, $i));
//         }
//         $trans: ($prop, $prop-opts);
//         $transitions: append($transitions, $trans, comma);
//     }

//     -webkit-transition: trans-prefix($transitions, webkit);
//     -moz-transition: trans-prefix($transitions, moz);
//     -o-transition: trans-prefix($transitions, o);
//     transition: $values;
// }
