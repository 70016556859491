@font-face {
	font-family: 'icomoon';
	src:  url('/assets/fonts/icomoon.eot?simze8');
	src:  url('/assets/fonts/icomoon.eot?simze8#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?simze8') format('truetype'),
  url('/assets/fonts/icomoon.woff?simze8') format('woff'),
  url('/assets/fonts/icomoon.svg?simze8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  vertical-align: initial;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: initial;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: initial;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e904";
}

.icon-quote:before {
  content: "\e903";
}

.icon-left:before {
  content: "\e902";
}

.icon-alumni:before {
  content: "\e901";
}

.icon-chevron-down:before {
  content: "\e900";
}

.icon-business:before {
  content: "\e908";
}

.icon-date:before {
  content: "\e909";
}

.icon-download:before {
  content: "\e90a";
}

.icon-fax:before {
  content: "\e90b";
}

.icon-home:before {
  content: "\e90c";
}

.icon-interning:before {
  content: "\e90d";
}

.icon-legal:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e90f";
}

.icon-mail:before {
  content: "\e910";
}

.icon-phone:before {
  content: "\e911";
}

.icon-print:before {
  content: "\e912";
}

.icon-search:before {
  content: "\e913";
}

.icon-share:before {
  content: "\e914";
}

.icon-toggle-arrow:before {
  content: "\e915";
}

.icon-training:before {
  content: "\e916";
}
