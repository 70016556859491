// footer-nav styles
.site-footer__nav {
	ul {
		padding-left: calc(100% - 90px);
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.site-footer__nav {
		line-height: 24px;

		ul {
			padding-left: 0;
		}
	}
}
