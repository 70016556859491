// footer-contactinfo styles
.site-footer__contact {
	.row {
		margin: 0 -10px;

		> * {
			padding: 0 10px;
			margin-bottom: 15px;
		}
	}

	p {
		color: $dnTextGray;
		margin: 0;
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.site-footer__contact {
		.row {
			margin: 0 -35px;

			> * {
				padding: 0 35px;
			}
		}
	}
}
