// site-footer styles
.site-footer {
	background-color: $dnOffWhite;

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	> * {
		@extend %clearfix;
	}


	&__top {
		position: relative;

		.container {
			padding-top: 18px;
			padding-bottom: 18px;
		}

		.logo {
			float: left;

			img { width: 240px; }
		}

		.back-to-top {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.line {
			background-color: $dnLine;
			width: calc(50% - 50px);
			height: 1px;
			float: right;
			margin: 17px 0;
		}

		.tag {
			position: absolute;
			right: 20px;
			font-size: calc-em(14px);
			line-height: 32px;
			font-style: italic;
			padding: 0 7px 0 16px;
			background-color: $dnOffWhite;
		}
	}

	&__middle {
		font-size: calc-em(13px);
		line-height: 28px;
		margin: 17px 0 25px;

		a {
			color: $dnTextGray;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__bottom {
		background-color: $dnBlue;
		color: #fff;
		font-size: calc-em(12px);
		line-height: 32px;
		padding: 20px 0;

		.copyright {
			float: left;
			margin: 0;
		}

		li {
			float: left;
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.site-footer {
		.container {
			padding: 0 50px;
		}

		&__top {
			.tag {
				right: 50px;
			}

			.container {
				padding: 18px 50px;
			}
		}
	}
}

// @media only screen and (min-width: $bpLgDesktop){
// 	.site-footer {
// 		&__top {
// 			.tag {
// 				right: 120px;
// 			}
// 		}
// 	}
// }

@media only screen and (max-width: $bpTablet - 1){
	.site-footer {
		&__top {
			.container {
				text-align: center;
				padding-top: 0;
				padding-bottom: 0;
			}

			.logo {
				margin-top: 15px;
				img { width: 175px; }
			}

			.back-to-top {
				position: static;
				transform: translate(0, 0);
			}
		}

		&__middle {
			font-size: calc-em(12px);
			line-height: 18px;
			margin: 17px 0 25px;
		}

		&__bottom {
			.copyright {
				margin-top: 5px;
			}
		}
	}
}

@media print {
	.site-footer {
		.container {
			padding: 0 20px;
		}

		&__top {
			.back-to-top {
				display: none;
			}
		}

		&__middle {
			.site-footer__nav {
				display: none;
			}

			.col-sm-6 {
				width: 100%;
			}
		}

		&__bottom {
			background-color: transparent;

			ul, p {
				float: left;
				width: 100%;
				color: #000;
			}

			li a {
				color: #000;
			}
		}
	}
}
