// select-clone styles
.select-clone-pair {
	position: relative;
	// margin-bottom: 20px;

	select {
		position: absolute;
		top: 0;
		opacity: 0;
		z-index: 2;
		// visibility: hidden;
	}
}


.select-clone {
	position: relative;

	button {
		border: none;
		font-size: calc-em(13px);
		line-height: 18px;
		padding: 12px;
		color: $dnPlaceholderGray;
		background-color: $dnInputGray;
		display: block;
		width: 100%;
		text-align: left;
	}

	i {
		position: absolute;
		color: $dnBlue;
		top: 50%;
		right: 12px;
		font-size: calc-em(5px);
		transform: translate(0, -50%);
		z-index: 2;
	}

	// ul {
	// 	@extend %list-unstyled;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	width: 100%;
	// 	z-index: 1;
	// }
}
