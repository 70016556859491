// resource-item styles
.resource-item {
    margin: 0 0 24px;
    border-bottom: 1px solid $dnBorder;

    &:last-child {
        border-bottom: none;
    }

    h3 {
        color: $dnBlue;
        font-size: calc-em(16px);
        line-height: 21px;
        margin: 0 0 2px;
        transition: all 0.3s;

        a {
            color: $dnBlue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        color: $dnTextGray;
        font-size: calc-em(12px);
        line-height: 16px;
        margin: 0 0 8px;
        // letter-spacing: 1px;

        &.date {
            color: $dnDate;
            line-height: 26px;
            margin: 0 0 2px;
            padding-left: 30px;
            position: relative;
            letter-spacing: 0px;
        }

        i {
            position: absolute;
            top: 6px;
            left: 0;
        }
    }

    > a {
        display: inline-block;
        margin: 0 0 20px;
        font-size: calc-em(12px);
        line-height: 16px;
        color: $dnBlue;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .type {
        margin: 0 0 20px;
    }
}

@media only screen and (min-width: $bpDesktop){
    .resource-item {
        h3 {
            font-size: calc-em(20px);
            line-height: 26px;
        }

        p, > a {
            font-size: calc-em(13px);
            line-height: 18px;
        }
    }
}
