// normal font
@font-face {
	font-family: 'BodoniStd-Book';
	src: url('../fonts/BodoniStd-Book.eot');
	src: url('../fonts/BodoniStd-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BodoniStd-Book.woff') format('woff'),
		url('../fonts/BodoniStd-Book.ttf') format('truetype'),
		url('../fonts/BodoniStd-Book.svg#') format('svg');
	font-weight: normal;
	font-style: normal;
}
