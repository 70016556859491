// back-to-top styles
.back-to-top {
	border: 6px solid $dnToTopGray;
	border-radius: 35px;
	width: 70px;
	height: 70px;
	background-color: transparent;
	padding: 0;
	margin: 0;
	position: relative;
	outline: 0;
	cursor: pointer;
	color: $dnToTopGray;
	transition: all 0.3s;

	.arrow {
		display: inline-block;
		position: absolute;
		top: 14px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 0;
		border-right: 9px solid transparent;
		border-bottom: 13px solid $dnToTopArrowGray;
		border-left: 9px solid transparent;
		transition: all 0.3s;
	}

	span {
		display: inline-block;
		width: 100%;
		position: absolute;
		top: 33px;
		left: 50%;
		transform: translate(-50%, 0);
		font-size: calc-em(8px);
		text-transform: uppercase;
	}

	&:hover {
		color: $dnBlue;
		border-color: $dnBlue;

		.arrow {
			border-bottom-color: $dnBlue;
		}
	}
}
