// breadcrumb styles
.breadcrumb {
	padding: 16px 0;
	border-bottom: 1px solid $dnBreadcrumbBorder;

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	li {
		float: left;

		&:after {
			content: '>';
			font-size: calc-em(12px);
			margin: 0 10px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	a, span {
		display: inline-block;
		font-size: calc-em(12px);
		line-height: 20px;
		color: $dnPlaceholderGray;
	}

	a {
		color: $dnBlue;
		text-decoration: none;

		&:hover {
			color: $dnBlue;
			text-decoration: underline;
		}

		&.active {
			color: $dnBlue;
		}
	}
}

@media print {
	.breadcrumb {
		display: none;
	}
}
