// profile styles
.profile {
	position: relative;

	&__image {
		background-color: $dnProfileImage;
		// @include aspect-ratio(320, 257, 100%);
		@include aspect-ratio(508, 562, 100%);

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		}
	}

	&__details {
		padding: 20px 15px;
		background-color: #fff;

		h1 {
			font-size: calc-em(19px);
			line-height: 1;
			color: $dnBlue;
			margin: 0 0 7px;
		}

		h2 {
			font-size: calc-em(13px);
			line-height: 17px;
			color: $dnBlue;
			margin: 0 0 6px;
			font-style: italic;
		}

		&--contact {
			margin-top: 5px;

			ul {
				@extend %list-unstyled;
				@extend %clearfix;
			}

			li {
				margin: 0 0 10px;
			}

			a {
				display: inline-block;
				color: $dnBlue;
				text-decoration: none;
				font-size: calc-em(12px);
				line-height: 14px;
				position: relative;
				padding-left: 27px;

				&:hover {
					text-decoration: underline;
				}

				i {
					color: $dnProfileIcon;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.icon-phone {
				font-size: calc-em(14px, 12px);
			}

			.icon-fax {
				font-size: calc-em(15px, 12px);
				top: -1px;
			}

			.icon-mail {
				font-size: calc-em(10px, 12px);
				top: 2px;
			}
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.profile {
		&__details {
			margin: 0 0 14px;
			transform: translate(0, -26px);
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.profile {
		position: relative;

		&__image {
			@include aspect-ratio(508, 562, 40%);
			width: 40%;
			position: absolute;
			top: 0;
			left: 0;

			img {
				@include image-cover;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
			}
		}

		&__details {
			float: right;
			width: calc(62.5% + 35px);
			margin-top: 32px;
			padding: 36px 50px;
			box-shadow: 0 0 20px $dnBorder;

			h1 {
				font-size: calc-em(37px);
				margin: 0 0 10px;
			}

			h2 {
				font-size: calc-em(24px);
				line-height: 30px;
				margin: 0 0 7px;
			}

			&--contact {
				li {
					float: left;
					display: inline-block;
					margin: 15px 25px 14px 0;

					&:last-child {
						margin-right: 0;
					}
				}

				a {
					font-size: calc-em(15px);
					line-height: 20px;
					padding-left: 36px;
				}

				.icon-phone {
					font-size: calc-em(22px, 15px);
					top: -1px;
				}

				.icon-fax {
					font-size: calc-em(23px, 15px);
					top: -2px;
				}

				.icon-mail {
					font-size: calc-em(15px, 15px);
					top: 3px;
				}
			}
		}

		&__details--practice-areas {
			float: right;
			display: inline-block;
			width: calc(62.5% - 90px);
			margin: 0 45px;
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.profile {
		max-width: 1280px;
		margin: 0 auto 50px;

		&__image {
			left: calc(50% - 640px);
		}
	}
}

@media print {
	.profile {
		min-height: initial !important;

		.container {
			padding: 0 20px;
		}

		&__image {
			@include aspect-ratio(508, 562, 20%);
			position: absolute;
			top: 0;
			right: 10px;
			width: 20%;
			z-index: 1;
			margin-top: 0 !important;
		}

		&__details {
			padding-left: 0;
		}

		.download {
			display: none !important;
		}

		// &__image {
		// 	left: 20px;
		// }
	}
}
