// header-links styles
ul.site-header__links--links {
	margin-bottom: 10px;
	text-align: center;

	li {
		display: inline-block;
		padding-left: 20px;
		margin-bottom: 6px;

		&:first-child {
			padding: 0;
		}
	}

	a {
		color: $dnLanguageSelector;
		display: inline-block;
		text-decoration: none;
		line-height: 1;
		font-weight: 700;
		padding-left: 27px;
		position: relative;
		text-transform: uppercase;

		i {
			position: absolute;
			top: 2px;
			left: 0;
			font-size: calc-em(14px, 12px);

			&.icon-mail {
				top: -1px;
				font-size: calc-em(12px, 12px);
			}
		}

		span {
			display: inline-block;
			margin: 0;
			min-width: 1px;
			height: 12px;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	ul.site-header__links--links {
		// float: left;
		position: relative;
		display: inline-block;
		// top: 153px;
		// right: 126px;
		opacity: 1;
		visibility: visible;
		transition: all 0.3s ease-out;

		li {
			float: none;
			padding-left: 40px;
			margin: 0;
		}

		a {
			padding-left: 30px;

			i {
				top: -1px;
				font-size: calc-em(16px, 12px);

				&.icon-mail {
					top: -1px;
					font-size: calc-em(12px, 12px);
				}
			}

			&:hover {
				color: $dnBlue;
				// color: $dnBlue !important;
				text-decoration: underline;
			}
		}

		.open & {
			// display: block;
			// text-align: center;
			margin-bottom: 20px;
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	ul.site-header__links--links {
		// right: calc(50% - 515px);
	}
}
