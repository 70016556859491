// resource-listing styles
.listing-results {
    &__resource {
        > ul {
            @extend %list-unstyled;
            margin-bottom: 25px;
        }

        .results {
            color: $dnTextGray;
            font-size: calc-em(12px);
            line-height: 16px;
            margin: 12px 0 20px;
        }
    }
}

@media only screen and (min-width: $bpDesktop) {
    .listing-results {
        &__resource {
            .results {
                font-size: calc-em(13px);
                line-height: 18px;
                margin: 13px 0 24px;
            }
        }
    }
}
