// profile-awards styles
.profile-awards {
	margin: 0;
	text-align: center;

	&-item {
		margin-bottom: 40px;

		&:before {
			content: '';
			height: 100%;
			vertical-align: middle;
			display: inline-block;
			width: 0;
		}
	}

	img {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		max-width: 100%;
		max-height: 110px;
	}
}

@media only screen and (min-width: $bpDesktop){
	.profile-awards {
		&-item {
			margin-bottom: 85px;
		}
	}
}

@media print {
	.profile-awards {
		&-item {
			float: left;

			&:before {
				display: none;
			}
		}
	}
}
