// home-testimonial styles
.home-testimonial {
	background-color: $dnSectionGray;
	padding: 33px 0 27px;

	// &__header, &__content {
	// 	padding: 0 10px;
	// }

	&__header {
		h2 {
			font-size: calc-em(22px);
			color: $dnBlue;
			margin: 0 0 15px;
		}

		p {
			letter-spacing: 1px;
			font-size: calc-em(12px);
			line-height: 16px;
			padding: 0 0 18px;
			margin: 0;
			color: $dnTextGray;
			border-bottom: 1px solid $dnBorder;
		}
	}

	&__content {
		position: relative;
		padding: 80px 0 65px;

		.icon-quote {
			display: inline-block;
			position: absolute;
			color: $dnBlue;
			font-size: calc-em(27px);

			&.open {
				top: 33px;
			}

			&.close {
				bottom: 22px;
				right: 10px;
				transform: scaleX(-1) scaleY(-1);
			}
		}
	}

	.slick-slider {
		position: static;
	}

	.slick-dots {
		@extend %list-unstyled;
		@extend %clearfix;
		display: inline-block;
		position: absolute;
		bottom: 5px;

		li {
			float: left;

			&.slick-active {
				button {
					background-color: $dnBlue;
					color: $dnBlue;
				}
			}
		}

		button {
			border-radius: 50%;
			border: 2px solid $dnBlue;
			width: 10px;
			height: 10px;
			overflow: hidden;
			color: transparent;
			background-color: transparent;
			cursor: pointer;
			padding: 0;
			margin: 0 4px;
			outline: 0;
		}
	}

	.slick-arrow {
		border: none;
		background-color: transparent;
		position: absolute;
		top: 33px;
		right: 0;
		cursor: pointer;
		outline: 0;

		i {
			font-size: calc-em(17px);
			color: $dnTestimonialArrow;
		}

		&:hover {
			i {
				color: $dnBlue;
			}
		}

		&.slick-next {
			i {
				display: inline-block;
				transform: scaleX(-1);
			}
		}

		&.slick-prev {
			right: 40px;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.home-testimonial {
		padding: 35px 0;

		.row {
			margin: 0;
		}

		&__header, &__content {
			float: left;
		}

		&__header {
			width: 40%;
			padding: 40px 40px 40px 0;
			position: relative;

			.valign {
				position: absolute;
				top: 50%;
				left: 0;
				right: 40px;
				transform: translate(0, -50%);
			}

			h2 {
				font-size: calc-em(30px);
				padding: 0 0 38px;
				margin: 0;
				position: relative;

				&:after {
					content: '';
					height: 2px;
					width: 86px;
					background-color: $dnTestimonialUnderline;
					display: inline-block;
					position: absolute;
					bottom: 20px;
					left: 0;
				}
			}

			p {
				font-size: calc-em(13px);
				line-height: 18px;
				padding: 0 40px 0 0;
				margin: 0;
				border-bottom: none;
			}
		}

		&__content {
			width: 60%;
			padding: 60px 0 65px 45px;
			border-left: 1px solid $dnBorder;

			.icon-quote {
				font-size: calc-em(32px);

				&.open {
					top: 0;
				}

				&.close {
					bottom: 30px;
					right: 0;
				}
			}
		}

		.slick-arrow {
			top: 0;
			right: 0;

			i {
				font-size: calc-em(20px);
			}

			&.slick-prev {
				right: 47px;
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	.home-testimonial {
		.container {
			padding: 0 160px;
		}
	}
}

@media print {
	.home-testimonial {
		page-break-inside: avoid;

		.container {
			padding: 0 20px;
		}
		&__header, &__content {
			height: auto !important;
		}
	}
}
