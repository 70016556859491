// button styles
.cta, .submit {
	font-size: calc-em(14px) !important;
	font-family: $primaryFont;
	padding: 12px 42px !important;
	text-transform: uppercase !important;
	background-color: transparent !important;
	border: 1px solid transparent !important;
	position: relative;
	font-weight: 700 !important;
	color: $dnBlue !important;
	cursor: pointer !important;
	display: inline-block !important;
	outline: 0;
	text-decoration: none;

	a {
		color: $dnBlue
	}

	span {
		position: relative;
		display: inline-block;
		transform: translate(0, 12px);
		-webkit-transition: all .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
		transition: all .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
	}

	&.download {
		span {
			padding-right: 24px;
		}

		i {
			position: absolute;
			right: 0;
		}
	}


	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		-webkit-transition: width .25s ease-in-out,height .25s ease-in-out,opacity 0ms .25s;
		transition: width .25s ease-in-out,height .25s ease-in-out,opacity 0ms .25s;
	}

	&:before {
		height: 1px;
		width: 34px;
		border-bottom: 1px solid $dnBlue;
		border-left: 1px solid $dnBlue;
		bottom: 0;
		left: 0;
	}

	&:after {
		height: 0;
		width: 0;
		border-right: 1px solid $dnBlue;
		border-top: 1px solid $dnBlue;
		opacity: 0;
		right: 0;
		top: 0;
	}

	&:hover {
		span {
			transform: translate(0, 0);
			-webkit-transition: all .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
			transition: all .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
		}

		&:before, &:after {
			height: 100%;
			width: 100%;
			opacity: 1;
			-webkit-transition: width .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
			transition: width .25s ease-in-out,height .25s ease-in-out,opacity 0ms;
		}
	}
}
