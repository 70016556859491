// cover-page styles
.cover-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.65);

    img, video {
        display: block;
        width: 35%;
        max-width: 480px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close-btn {
        position: absolute;
        top: 29px;
        right: 29px;
        background-color: transparent;
        color: $dnJoinusBlack;
        border: none;
        font-size: calc-em(32px);
        cursor: pointer;
        display: block;
        padding: 0;
        outline: 0;

        i {
            display: block;
            padding: 10px;
        }
    }
}

@media only screen and (max-width: $bpDesktop - 1){
    .cover-page {
        .close-btn {
            top: 12px;
            right: 15px;
            font-size: calc-em(24px);

            i {
                padding: 5px;
            }
        }

        img, video {
            width: 80%;
        }
    }
}
