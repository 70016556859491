// home-banner styles
.home-banner {
	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 520px;

	&__image {
        margin: 0 !important;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		Left: 0;

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		position: absolute;
		width: 100%;
		bottom: 250px;
		// top: 30%;
		// transform: translate(0, -50%);
		padding: 0;

		h1 {
			color: $dnBlue;
			font-size: calc-em(38px);
			margin: 0 0 15px;
			text-align: center;
			text-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
			font-weight: normal;
		}
	}
}

@media only screen and (min-width: $bpTablet){
	.home-banner {
		max-height: 730px;

		&.home2 {
			max-height: 640px;
		}

		&__text {
			bottom: 140px;

			h1 {
				font-size: calc-em(48px);
				margin: 0 0 23px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.home-banner {
		&__text {
			// top: 50%;
			// transform: translate(0, -50%);

			h1 {
				font-size: calc-em(78px);
				margin: 0 0 23px;
			}
		}
	}
}
