// site-header styles
.site-header {
	position: fixed;
	top: 0;
	z-index: 10;
	width: 100%;
	padding: 15px 0;
	font-size: calc-em(11px);
	line-height: 28px;
	background-color: #fff;
	height: 91px;

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	.container > * {
		@extend %clearfix;
	}

	&__logo {
		position: relative;
		z-index: 9;
		opacity: 1;
		float: left;
		transition: all 0.3s ease-in;
		top: 4px;

		a {
			display: block;
			height: 20px;
		}

		img {
			display: block;
			max-height: 100%;
			height: 100%;
		}
	}

	&__links {
		@extend %clearfix;
		opacity: 1;
		transition: all 0.2s ease-out;
	}

	&__menu {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 7;
		display: block;
		background-color: $dnMenuGray;
		padding: 58px 0 30px;
		text-align: center;
		transform: translate(0, -50px);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-out;
		max-height: 480px;
		height: 100vh;
		overflow: auto;
	}

	&__hamburger {
		float: right;
		width: 28px;
		height: 28px;
		position: relative;
		margin-left: 22px;
		z-index: 8;

		.line {
			position: absolute;
			width: 28px;
			height: 2px;
			background-color: $dnBlue;
			right: 0;
			transition: all 0.2s;

			&.line1 {
				top: 8px;
			}

			&.line2 {
				top: 13px;
			}

			&.line3 {
				bottom: 8px;
				width: 15px;
			}
		}

		&.active {
			.line {
				&.line1 {
					width: 11px;
				}

				&.line2 {
					width: 22px;
				}

				&.line3 {
					width: 17px;
				}
			}
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.site-header {
		box-shadow: 0 -3px 20px $dnLine;
		height: 58px;

		&.open {
			.site-header__menu {
				opacity: 1;
				visibility: visible;
				transform: translate(0, 0);
			}
		}

		.nonscroll-desktop {
			display: none !important;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.site-header {
		&__logo {
			top: 0;

			a {
				height: 28px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-header {
		// position: fixed;
		// top: 0;
		// z-index: 10;
		padding: 20px 0;
		font-size: calc-em(12px);
		line-height: 50px;
		background-color: rgba(255,255,255,0);
		transition: all 0.3s ease-in;
		border-bottom: 1px solid $dnBodyBorder;

		.scroll-desktop {
			display: none;
		}

		&.is-home {
			height: 110px;
			border: none;
			padding: 30px 0;

			.site-header__menu {
				transform: translate(0, -108px);
			}

			.site-header__background {
				background-color: #fff;
				opacity: 0;
			}
		}

		&.open {
			.site-header__logo {
				opacity: 0;
				transform: translate(-50px, 0);
			}

			.site-header__menu {
				&--links .container > div {
					right: 50%;
					transform: translate(50%, 0);
					transition: all 0.3s ease-out;
				}
			}

			.site-header__links--language {
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-out;
			}
		}

		&.show-nav {
			.site-header__menu {
				opacity: 0.96;
				visibility: visible;
				background-color: $dnMenuGray;
				transform: translate(0, 0);
				box-shadow: 0 -3px 20px $dnLine;

				> * {
					opacity: 1;
					visibility: visible;
					transition: opacity 0.3s ease-out;
				}
			}
		}

		&__background {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $dnOffWhite;
			opacity: 1;
			box-shadow: 0 -3px 20px $dnLine;
		}

		.container {
			padding: 0 50px;
		}

		&__logo {
			// top: -6px;

			a {
				height: 50px;
			}
		}

		&__menu {
			display: block;
			width: 100%;
			padding: 80px 0 45px;
			background-color: transparent;
			transform: translate(0, -118px);
			opacity: 1;
			visibility: hidden;
			max-height: 630px;

			&--links {
				position: absolute;
				display: inline-block;
				width: 100%;
				top: 138px;
				left: 0;
				transition: all 0.3s ease-out;

				.container {
					width: 100%;
					padding: 0;
					text-align: center;

					> div {
						position: absolute;
						right: 126px;
						transform: translate(0, 0);
						display: inline-block;
						transition: all 0.3s ease-out;
					}
				}
			}

			.site-header__search, .site-header__nav  {
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.3s ease-out;
			}

			.site-header__links--language {
				position: absolute;
				width: auto;
				left: -40px;
				transform: translate(-100%, -1px);
				// right: 100%;
				// top: 0;
				transition: all 0.3s ease-out;
			}
		}

		&__hamburger {
			float: none;
			position: absolute;
			top: 7px;
			right: 50px;
			width: 36px;
			height: 36px;
			margin: 0;
			cursor: pointer;

			.line {
				position: absolute;
				width: 36px;
				height: 2px;
				background-color: $dnBlue;
				right: 0;
				transition: all 0.2s;

				&.line1 {
					top: 10px;
				}

				&.line2 {
					top: 17px;
				}

				&.line3 {
					bottom: 10px;
					width: 18px;
				}
			}

			&:hover, &.active {
				.line {
					&.line1 {
						width: 19px;
					}

					&.line2 {
						width: 30px;
					}

					&.line3 {
						width: 25px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	.site-header {
		&__menu {
			&--links {
				// right: calc(50% - 515px);
			}
		}
	}
}

@media print {
	.site-header {
		background-color: transparent;
		position: static;

		.container {
			padding: 0 20px;
		}

		&.is-home {
			position: absolute;
		}

		&__logo {
			img {
				display: none;
			}

			.small-mobile-mobile.nonscroll-desktop {
				display: block;
			}
		}

		&__hamburger {
			display: none;
		}
	}
}
