// home-search styles
.home-search,
.listing-filter__lawyer,
.listing-filter__resource {
	@extend %clearfix;
	padding: 40px 17px;
	background-color: rgba(255, 255, 255, 0.85);
	box-shadow: 0 0 30px rgba(172, 172, 172, 0.35);
	position: relative;
	z-index: 1;

	.row {
		margin: 0;
	}

	.search-label {
		font-size: calc-em(19px);
		margin: 0 0 12px;
		font-weight: 600;
		color: $dnBlue;
		font-family: $titleFont;
		font-weight: 700;
	}

	&__lawyer,
	&__expertise {
		padding: 0;
		border-bottom: 1px solid $lawyerSearchBorder;
		padding-bottom: 30px;
		position: relative;
	}

	&__expertise {
		border-bottom: none;
		  padding-top: 30px;
		  padding-bottom: 0;
	}

	.typeahead__container {
		@extend %clearfix;
	}

	.typeahead__result {
		position: relative;
		top: -20px;

		.typeahead__list {
			border: 0;
			margin: 0;
			padding: 0;
			border-radius: 0;
			font-size: calc-em(13px);

			.typeahead__item {
				border: 0;
				font-family: $primaryFont;

				&:first-child {
					a {
						border-top: 1px solid $dnBorder;
					}

					&.active a {
						border-top: 1px solid $dnBlue;
					}
				}

				a {
					color: $dnJoinusBlack !important;
					font-family: $primaryFont;
					border-right: 1px solid $dnBorder;
					border-left: 1px solid $dnBorder;
					border-bottom: 1px solid $dnBorder;
				}

				&.active a{
					border-right: 1px solid $dnBlue;
					border-bottom: 1px solid $dnBlue;
					border-left: 1px solid $dnBlue;
					background-color: $dnBlue !important;
					color: #fff !important;
				}
			}
		}
	}

	.field-group {
		margin-bottom: 20px;

		&.search-button {
			margin: 0;
		}
	}

	label, input, select {
		display: block;
		width: 100%;
		font-size: calc-em(13px);
		font-weight: 400;
		line-height: 18px;
		border: none;
		color: lighten($dnPracticeAreaText, 20%);
		border-radius: 0 !important;
		-webkit-appearance: none !important;
		font-family: $primaryFont;
	}

	.select-clone button {
		font-family: $primaryFont;
		font-weight: 400;
		color: lighten($dnPracticeAreaText, 20%);
	}

	label {
		color: $dnTitleBlack;
		font-weight: 700;
		margin-bottom: 5px;
	}

	input, select, option {
		background-color: $dnInputGray;
		padding: 12px;
		outline: 0;
		border: none;
		color: lighten($dnPracticeAreaText, 20%);

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: lighten($dnPracticeAreaText, 20%);
			font-weight: 400;
			opacity: 1;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: lighten($dnPracticeAreaText, 20%);
			font-weight: 400;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: lighten($dnPracticeAreaText, 20%);
			font-weight: 400;
			opacity: 1;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: lighten($dnPracticeAreaText, 20%);
			font-weight: 400;
			opacity: 1;
		}
	}
}

.home-lawyer-search {
	margin-top: -30px;

	.container {
		margin: 0 auto -150px;
	}
}

.home-search {
	margin: auto;
	transform: translate(0, -50%);
	background-color: rgba(255, 255, 255, 1);
}

@media only screen and (min-width: $bpTablet), print {
	.home-search,
	.listing-filter__lawyer,
	.listing-filter__resource {
		padding: 40px;
		max-width: 885px;

		.search-label {
			font-size: calc-em(23px);
			margin: 0 0 14px;
			color: $dnBlue;
			font-family: $titleFont;
		}

		.field-group {
			&.search-button {
				position: absolute;
				bottom: 0px;
				right: 40px;
			}
		}

		&__lawyer,
		&__expertise {
			border-bottom: none;
			border-right: 1px solid $lawyerSearchBorder;
			padding-right: 40px;
			padding-bottom: 42px;
			position: relative;
		}

		&__expertise {
			padding-top: 0;
			padding-right: 10px;
			padding-left: 40px;
			border-right: none;

			.field-group {
				&.search-button {
					right: 10px;
				}
			}
		}

		label, input, select {
			font-size: calc-em(14px);
			line-height: 18px;
		}

		.select-clone button {
			font-size: calc-em(14px);
		}

		#practice-sector, #search-keyword {
			// width: calc(50% - 93px);
			width: 100%;
			float: left;
			margin: 0 0 20px;
		}
	}

	.home-lawyer-search {
		.container {
			margin: 0 auto -115px;
		}
	}

	.home-search {
		margin: auto;
	}
}
