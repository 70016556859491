// search-listing styles
.search-listing {
    margin: 0 10px 30px;

    h3 {
        margin-bottom: 10px;
    }

    .searchBox {
        @extend %clearfix;


        label, input[type="submit"] {
            @extend %visuallyHidden;
        }

        .predictiveSearchHolder {
            position: relative;
            z-index: 1;
        }

        .predictiveSearchResults {
            width: calc(100% - 170px);
            background-color: #fff;

            a {
                color: $dnJoinusBlack !important;
                font-family: $primaryFont;
                border-right: 1px solid $dnBorder;
                border-left: 1px solid $dnBorder;
                border-bottom: 1px solid $dnBorder;
                display: block;
                padding: 0.5rem 0.75rem;
                clear: both;
                text-decoration: none;
                cursor: pointer;

                &.selectedResult {
                    border-right: 1px solid $dnBlue;
                    border-bottom: 1px solid $dnBlue;
                    border-left: 1px solid $dnBlue;
                    background-color: $dnBlue !important;
                    color: #fff !important;
                }
            }
        }

        input:not([type="submit"]) {
            font-size: calc-em(14px);
            font-weight: 400;
            line-height: 18px;
            background-color: $dnInputGray;
            padding: 12px;
            outline: 0;
            border: none;
            color: lighten($dnPracticeAreaText, 20%);
            width: calc(100% - 170px);

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: lighten($dnPracticeAreaText, 20%);
                font-weight: 400;
                opacity: 1;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: lighten($dnPracticeAreaText, 20%);
                font-weight: 400;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: lighten($dnPracticeAreaText, 20%);
                font-weight: 400;
                opacity: 1;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: lighten($dnPracticeAreaText, 20%);
                font-weight: 400;
                opacity: 1;
            }
        }

        .search-submit-clone {
            float: right;
        }
    }

    .resource-item:nth-last-child(2) {
        border-bottom: none;
    }

    table.checkbox {
        margin: 10px 0 32px;

        td {
            min-width: 110px;

            label {
                display: inline-block;
                position: relative;
                top: 2px;
                margin-left: 10px;
            }
        }

    }
}

.search-pagination {
    text-align: center;
    margin: 45px 0 60px;

    a, strong {
        display: inline-block;
        padding: 0 10px;
        font-size: calc-em(12px);
        line-height: 12px;
        color: $dnPlaceholderGray;
        display: inline-block;
        transition: all 0.3s;
        font-weight: 700;
        text-decoration: none;

        &:hover {
            color: $dnBlue;
            text-decoration: underline;
        }
    }

    strong {
        color: $dnBlue;
        text-decoration: underline;
    }
}

@media only screen and (max-width: $bpDesktop - 1) {
    .search-listing {
        margin-top: 45px;
    }
}

@media only screen and (max-width: $bpTablet - 1) {
    .search-listing {
        input:not([type="submit"]) {
            font-size: calc-em(13px);
        }
    }
    .search-pagination {
        a, strong {
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: $bpLgMobile - 1) {
    .search-listing {
        .searchBox {
            input:not([type="submit"]) {
                width: 100%;
            }

            .predictiveSearchResults {
                width: 100%;
            }

            .predictiveSearchHolder {
                top: -58px;
            }

            .search-submit-clone {
                width: 100%;
                margin-top: 16px;
                float: none;
            }
        }
    }
}
