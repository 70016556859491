// profile-practice-areas styles
.profile__details--practice-areas {
	padding-bottom: 30px;
	border-bottom: 1px solid $dnBorder;

	h3 {
		font-size: calc-em(19px);
		line-height: 1;
		color: $dnBlue;
		margin: 0 0 13px;
	}

	ul {
		@extend %clearfix;
		@extend %list-unstyled;
	}

	a {
		display: inline-block;
		font-size: calc-em(13px);
		line-height: 27px;
		color: $dnPracticeAreaText;
		text-decoration: none;
		padding-left: 20px;
		position: relative;

		i {
			position: absolute;
			top: 9px;
			left: 0;
			font-size: calc-em(9px, 13px);
			color: $dnBlue;
			transform: scaleX(-1);
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.profile__details--practice-areas {
		.row {
			margin: 0;
		}

		li {
			padding: 0;
		}
	}
}

@media only screen and (min-width: $bpTablet), print {
	.profile__details--practice-areas {
		padding: 38px 0 26px;
		border-bottom: 1px solid $dnBorder;

		li:nth-child(odd) {
			float: left;
		}

		li:nth-child(even) {
			float: right;
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.profile__details--practice-areas {
		h3 {
			font-size: calc-em(26px);
			line-height: 1;
			margin: 0 0 16px;
		}

		a {
			font-size: calc-em(13px);
			line-height: 30px;
			padding-left: 20px;

			i {
				font-size: calc-em(9px, 13px);
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.profile__details--practice-areas {
		a {
			font-size: calc-em(15px);

			i {
				font-size: calc-em(9px, 15px);
				top: 10px;
			}
		}
	}
}
