// header styles
.layout-main header {
    margin: 30px 0 30px !important;
}

@media only screen and (min-width: $bpDesktop){
    .layout-main header {
        margin: 0 0 30px !important;
    }
}
