// expertise-contacts-item styles
.expertise-contacts-item {
	padding: 0 10px;
	display: block;
	text-decoration: none;

	&:hover {
		.expertise-contacts-item__text {
			h3 {
				text-decoration: underline;
			}
		}
	}

	&__image {
		@include aspect-ratio(155, 180, 100%);
		margin-bottom: 11px;

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		h3 {
			color: $dnBlue;
			margin: 0 0 8px;
			font-size: calc-em(16px);
			line-height: 1;
		}

		p {
			font-size: calc-em(12px);
			line-height: 16px;
			margin: 0 0 6px;
			color: $dnTestimonialArrow;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.expertise-contacts-item {
		padding: 0 18px;

		&__image {
			margin-bottom: 16px;
		}

		&__text {
			h3 {
				margin: 0 0 8px;
				font-size: calc-em(17px);
				line-height: 1;
			}

			p {
				font-size: calc-em(13px);
				line-height: 19px;
				margin: 0 0 8px;

				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

@media print {
	.expertise-contacts-item {
		page-break-inside: avoid;
		min-height: 320px;
	}
}
