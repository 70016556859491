// pagination styles
// pagination styles
.listing-pagination {
    padding: 0;
    // margin: 0 auto 50px;
    text-align: center;

    ul {
        @extend %list-unstyled;
        @extend %clearfix;
        display: inline-block;
    }

    li {
        float: left;
        display: inline-block;
        margin: 0 2px;
        border: 1px solid transparent;
        transition: all 0.3s;

        a {
            font-size: calc-em(12px);
            line-height: 12px;
            color: $dnPlaceholderGray;
            display: inline-block;
            padding: 11px 10px;
            transition: all 0.3s;
            font-weight: 700;
            text-decoration: none;
        }

        &:hover,
        &.active {
            a {
                color: $dnBlue;
                text-decoration: underline;
            }
        }
    }

    .first, .prev,
    .next, .last {
        a {
            font-size: calc-em(12px);
            text-decoration: none;
        }

        &:hover,
        &.active {
            a {
                text-decoration: none;
                background-color: $dnBlue;
                color: #fff;
            }
        }
    }

    .next,
    .last {
        i {
            display: inline-block;
            transform: scaleX(-1);
        }
    }
}

@media only screen and (max-width: $bpTablet - 1) {
    .listing-pagination {
        li {
            a {
                padding: 9px 8px;
                font-size: calc-em(12px);
                font-weight: 400;
            }
        }
    }
}
