//Import helpers
@import './_variables';
@import './_mixins';
@import './_helpers';
@import './_fonts';
@import './_icons';
@import './_grids';

// Normalize Styles
@import 'node_modules/normalize.css/normalize';

//Plugin Styles
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/pikaday/scss/pikaday';
@import 'node_modules/jquery-typeahead/src/jquery.typeahead.scss';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.fixed {
	position: fixed;
}

body {
	position: relative;
	font-family: $primaryFont;
	color: $dnTextBlack;
	font-size: calc-em(16px);
	background-color: $dnOffWhite;
	width: 100%;

	&.cover-page-active {
		position: fixed;
	}
}

img {
	max-width: 100%;
}

.page-utilities {
	@extend %clearfix;
	position: relative;
}

@media only screen and (max-width: $bpDesktop - 1) {
	.main-content {
		margin-top: 57px;
	}

	// Realign AddThis
	#at15s {
		margin-top: -57px !important;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.main-content:not(.layout-home) {
		margin-top: 91px;

		>.container {
			padding: 0 50px;
		}
	}

	// [class^="layout-"]:not(.layout-home),
	// [class*=" layout-"]:not(.layout-home) {
	// 	> .container {
	// 		padding: 0 50px;
	// 	}
	// }

	// Realign AddThis
	#at15s {
		margin-top: -91px !important;
	}
}

@media print {
	.layout-main {
		width: 100%;
	}
}

a {
	transition: all 0.3s ease-out;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $titleFont;
}

// Import Modules
// Common
@import '../_modules/common/header/header';
@import '../_modules/common/section/section';
@import '../_modules/common/select-clone/select-clone';
@import '../_modules/common/table-responsive/table-responsive';

// Atoms
@import '../_modules/atoms/button/button';
@import '../_modules/atoms/back-to-top/back-to-top';
@import '../_modules/atoms/back-to-page/back-to-page';

// Molecules
@import '../_modules/molecules/cover-page/cover-page';
@import '../_modules/molecules/header-language/header-language';
@import '../_modules/molecules/header-search/header-search';
@import '../_modules/molecules/header-links/header-links';
@import '../_modules/molecules/header-nav/header-nav';
@import '../_modules/molecules/home-news-item/home-news-item';
@import '../_modules/molecules/home-testimonial-item/home-testimonial-item';
@import '../_modules/molecules/home-joinus-item/home-joinus-item';
@import '../_modules/molecules/footer-contactinfo/footer-contactinfo';
@import '../_modules/molecules/footer-links/footer-links';
@import '../_modules/molecules/footer-nav/footer-nav';
@import '../_modules/molecules/breadcrumb/breadcrumb';
@import '../_modules/molecules/page-actions/page-actions';
@import '../_modules/molecules/text-snippet/text-snippet';
@import '../_modules/molecules/expertise-contacts-item/expertise-contacts-item';
@import '../_modules/molecules/related-links/related-links';
@import '../_modules/molecules/profile-practice-areas/profile-practice-areas';
@import '../_modules/molecules/side-nav/side-nav';
@import '../_modules/molecules/resource-item/resource-item';
@import '../_modules/molecules/lawyer-item/lawyer-item';
@import '../_modules/molecules/pagination/pagination';

// Organisms
@import '../_modules/organisms/site-header/site-header';
@import '../_modules/organisms/home-banner/home-banner';
@import '../_modules/organisms/home-search/home-search';
@import '../_modules/organisms/home-news/home-news';
@import '../_modules/organisms/home-testimonial/home-testimonial';
@import '../_modules/organisms/home-joinus/home-joinus';
@import '../_modules/organisms/home-connect/home-connect';
@import '../_modules/organisms/expertise-contacts/expertise-contacts';
@import '../_modules/organisms/profile/profile';
@import '../_modules/organisms/tab/tab';
@import '../_modules/organisms/rte/rte';
@import '../_modules/organisms/profile-qualifications/profile-qualifications';
@import '../_modules/organisms/profile-awards/profile-awards';
@import '../_modules/organisms/form/form';
@import '../_modules/organisms/lawyer-filters/lawyer-filters';
@import '../_modules/organisms/resource-filters/resource-filters';
@import '../_modules/organisms/lawyer-listing/lawyer-listing';
@import '../_modules/organisms/resource-listing/resource-listing';
@import '../_modules/organisms/search-listing/search-listing';
@import '../_modules/organisms/site-footer/site-footer';
