// Media query breakpoints
$bpMobile: 320px;
$bpLgMobile: 480px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpLgDesktop: 1280px;

// font settings
$base-fontsize: 16px;
// $titleFont: 'Cardo', Arial, sans-serif;
$titleFont: 'BodoniStd-Book', Arial, sans-serif;
$primaryFont: Arial, sans-serif;

/* COLOURS */
// Blue
$dnBlue: #001d55;

// Gray
$dnTextGray: #6d6d6d;
$dnToTopGray: #9a918a;
$dnToTopArrowGray: #655d56;
$dnSectionGray: #f0f0f0;
$dnLine: #acacac;
$dnInputGray: #e8e9ed;
$dnPlaceholderGray: #545353;
$dnBorder: #d7d7d7;
$dnTestimonialUnderline: #9a918a;
$dnTestimonialArrow: #a7a7a7;
$dnTabs: #b4b4b4;
$dnDate: #ababab;
$dnLanguageSelector: #8e8d8d;
$dnMenuGray: #f1f1f1;
$dnBodyBorder: #d2d2d2;
$dnBreadcrumbBorder: #e6e6e6;
$dnProfileImage: #f3f1f6;
$lawyerSearchBorder: #c3c3c3;

// White
$dnOffWhite: #f9f9f9;
$dnOffWhiteRGB: rgba(249,249,249,1);
$dnOffWhiteRGBT: rgba(249,249,249,0);

// Black
$dnTitleBlack: #2f2f2f;
$dnTextBlack: #5f5f5f;
$dnJoinusBlack: #4e4e4e;
$dnSearchIconBlack: #5b5a5a;
$dnNavTextBlack: #303030;
$dnPracticeAreaText: #4b4b4b;
$dnProfileIcon: #827b75;
