// home-joinus styles
.home-joinus {
	margin: 40px 0;

	&__header {
		h2 {
			color: $dnBlue;
			font-size: calc-em(22px);
			margin: 0 0 25px;
		}
	}
}

@media only screen and (min-width: $bpTablet), print {
	.home-joinus {
		margin: 72px 0 42px;

		&__header {
			h2 {
				font-size: calc-em(36px);
				margin: 0 0 50px;
			}
		}

		&__content {
			@include justify;
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.home-joinus {
		.container {
			padding: 0 160px;
		}
	}
}

@media print {
	.home-joinus {
		page-break-inside: avoid;

		.container {
			padding: 0 20px;
		}
	}
}
