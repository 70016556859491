// page-actions styles
.page-actions {
	float: right;
	margin: 30px 0 42px;

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	li {
		float: left;
		margin-right: 20px;

		&:last-child {
			margin: 0;
		}
	}

	a {
		display: inline-block;
		font-size: calc-em(12px);
		line-height: 20px;
		color: $dnPlaceholderGray;
		padding-left: 30px;
		position: relative;
		text-decoration: none;

		&:hover {
			color: $dnBlue;
			text-decoration: underline;
		}

		i {
			display: inline-block;
			position: absolute;
			top: 2px;
			left: 0;
		}

		.icon-print {
			font-size: calc-em(17px, 12px);
		}

		.icon-mail {
			top: 5px;
		}

		.icon-share {
			font-size: calc-em(16px, 12px);
		}
	}
}

@media print {
	.page-utilities {
		display: none;
	}

	.page-actions {
		display: none;
	}
}
