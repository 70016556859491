// home-news-item styles
.home-news-item {
	display: block;
	margin: 0 0 24px;
	text-decoration: none;

	&:hover {
		h3 {
			color: $dnBlue;
			text-decoration: underline;
		}
	}

	&__image {
		display: none;
	}

	&__text {
		border-bottom: 1px solid $dnBorder;

		h3 {
			color: $dnJoinusBlack;
			font-size: calc-em(16px);
			line-height: 21px;
			margin: 0 0 2px;
			transition: all 0.3s;
		}

		p {
			color: $dnTextGray;
			font-size: calc-em(12px);
			line-height: 16px;
			margin: 0 0 24px;
			letter-spacing: 1px;

			&.date {
				color: $dnDate;
				line-height: 26px;
				margin: 0 0 2px;
				padding-left: 30px;
				position: relative;
				letter-spacing: 0px;
			}

			i {
				position: absolute;
				top: 6px;
				left: 0;
			}
		}
	}

	&:nth-last-child(2) {
		margin: 0 0 10px;

		.home-news-item__text {
			border: none;

			p {
				margin: 0 0 2px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.home-news-item {
		float: left;
		width: 60%;
		padding: 0 22px;

		&__image {
			// display: block;
			margin: 0 0 18px;
			@include aspect-ratio(335, 245, 100%);

			img {
				@include image-cover;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&__text {
			h3 {
				font-size: calc-em(20px);
				line-height: 26px;
			}

			p {
				font-size: calc-em(13px);
				line-height: 18px;
			}
		}

		&:first-child {
			width: 40%;
			margin-bottom: 0;

			.home-news-item__image {
				display: block;
			}

			.home-news-item__text {
				border-bottom: none;

				p {
					margin: 0 0 16px;

					&.date {
						margin: 0 0 2px;
					}
				}
			}
		}
	}
}

@media print {
	.home-news-item {
		page-break-inside: avoid;
	}
}
