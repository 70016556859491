// home-news styles
.home-news {
	&__header {
		margin-bottom: 33px;

		h2 {
			font-size: calc-em(22px);
			color: $dnBlue;
			margin: 0 0 20px;
			position: relative;
			width: 200px;
		}
	}

	&__tab {
		position: relative;

		ul {
			@extend %clearfix;
			@extend %list-unstyled;
		}
	}

	&__category {
		@extend %clearfix;
		display: none;
		padding: 0 10px;

		&.active {
			display: block;
		}
	}

	&__cta {
		clear: both;
		text-align: center;
		padding: 15px 0 10px;
	}
}

@media only screen and (max-width: $bpDesktop - 1){
	.home-news {
		&__tab {
			.selected-option {
				color: $dnBlue;
				display: inline-block;
				width: 100%;
				font-size: calc-em(14px);
				line-height: 1;
				padding: 13px 0;
				text-align: center;
				position: relative;
				background-color: $dnInputGray;

				i {
					position: absolute;
					top: 50%;
					right: 12px;
					font-size: calc-em(5px);
					transform: translate(0, -50%);
					z-index: 2;
					transition: all 0.3s;
				}

				&.active {
					i {
						transform: translate(0, -50%) scaleY(-1);
					}
				}
			}

			ul {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in;
				z-index: 2;

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			li {
				width: 100%;
				display: inline-block;
				text-align: center;
				background-color: $dnInputGray;
				color: $dnBlue;
			}

			a {
				display: inline-block;
				color: $dnBlue;
				text-decoration: none;
				width: 100%;
				font-size: calc-em(14px);
				line-height: 1;
				padding: 13px 0;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop), print {
	.home-news {
		&__header {
			border-bottom: 1px solid $dnBorder;

			h2 {
				font-size: calc-em(30px);
				margin: 35px 0 13px;
			}
		}

		&__tab {

			ul {
				position: absolute;
				right: 0;
				bottom: -1px;
			}

			li {
				float: left;
				padding: 0 8px;

				&:after {
					color: $dnTabs;
					content: '|';
					display: inline-block;
					position: relative;
					vertical-align: middle;
					margin-top: -3px;
					margin-left: 18px;
				}

				&:hover, &.active {
					a {
						color: $dnBlue;
						border-bottom: 3px solid $dnBlue;
					}
				}

				&:last-child {
					padding-right: 0;

					&:after {
						display: none;
					}
				}
			}

			a {
				color: $dnTabs;
				display: inline-block;
				padding: 13px 12px 21px;
				font-size: calc-em(14px);
				text-decoration: none;
				border-bottom: 3px solid transparent;
			}
		}

		&__content {
			margin: 0 -22px;
		}

		&__category {
			padding: 0;
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.home-news {
		.container {
			padding: 0 140px;
		}
	}
}

@media print {
	.home-news {
		.container {
			padding: 0 20px;
		}
		&__tab ul {
			position: static;
		}

		&__content {
			margin: 0;
		}

		&__cta {
			display: none;
		}
	}
}
