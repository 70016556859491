// header-nav styles
nav.site-header__nav {
	li {
		margin-bottom: 6px;

		&:last-child {
			margin: 0;
		}
	}

	a {
		font-size: calc-em(18px, 11px);
		line-height: 35px;
		font-weight: bold;
		color: $dnNavTextBlack;
		text-transform: capitalize;
		display: inline-block;
		text-decoration: none;
	}
}

@media only screen and (min-width: $bpDesktop){
	nav.site-header__nav {
		li {
			line-height: 45px;
		}

		a {
			font-size: calc-em(30px, 12px);
			line-height: 40px;
			transition: all 0.3s;

			&:hover {
				text-decoration: underline;
				color: $dnBlue;
			}
		}
	}
}
