// back-to-page styles
.back-to-page {
	font-size: calc-em(13px);
	line-height: 20px;
	text-decoration: none;
	color: $dnBlue;
	font-weight: bold;
	padding-left: 30px;
	position: relative;
	display: inline-block;
	margin: 16px 0;

	&:hover {
		text-decoration: underline;
	}

	i {
		font-size: calc-em(8px, 13px);
		position: absolute;
		top: -1px;
		left: 0;
		width: 20px;
		height: 20px;
		padding: 6px 0;
		border-radius: 50%;
		color: #fff;
		background-color: $dnBlue;
		text-align: center;
	}
}

@media only screen and (min-width: $bpDesktop){
	.back-to-page {
		margin: 30px 0 42px;
	}
}
