// lawyer-filters styles
.listing-filter__lawyer {
    margin: 0 auto;
	box-shadow: none;
    padding: 30px 15px;
    text-align: left;
}

@media only screen and (min-width: $bpTablet){
    .listing-filter__lawyer {
        padding: 25px;
    }
}

// lawyer search banner styles
.lawyer-search-banner {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 520px;

    &__image {
        margin: 0 !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        Left: 0;

        img {
            @include image-cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        padding: 0;
        text-align: center;

        h1 {
            color: $dnBlue;
            font-size: calc-em(24px);
            margin: 0 0 8px;
        }
    }
}

@media only screen and (min-width: $bpTablet) {
    .lawyer-search-banner {
        max-height: 650px;

        &__text {
            h1 {
                font-size: calc-em(40px);
                margin: 0 0 12px;
            }
        }
    }
}

@media only screen and (min-width: $bpDesktop) {
    .lawyer-search-banner {
        &__text {
            h1 {
                font-size: calc-em(65px);
                margin: 0 0 12px;
            }
        }
    }
}

