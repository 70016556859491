// lawyer-item styles
.lawyer-item {
    margin: 0 0 24px;
    border-bottom: 1px solid $dnBorder;

    &:last-child {
        border-bottom: none;
    }

    &__image {
        float: left;
        padding: 0 10px;
        max-width: 175px;
        margin-bottom: 24px;

        .image-fit {
            @include aspect-ratio(155, 180, 100%);

            img {
                @include image-cover;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__text {
        float: left;
        padding: 0 10px;
        min-width: calc(100% - 175px);

        h3 {
            color: $dnBlue;
            font-size: calc-em(16px);
            line-height: 21px;
            margin: 0 0 3px;
            transition: all 0.3s;

            a {
                color: $dnBlue;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .designation {
            margin-bottom: 6px;
        }

        p {
            color: $dnTextGray;
            font-size: calc-em(12px);
            line-height: 16px;
            margin: 0 0 6px;

            a {
                color: $dnTextGray;
                text-decoration: none;
                position: relative;
                line-height: 16px;
                margin: 0 20px 2px 0;
                position: relative;
                letter-spacing: 0px;

                i {
                    position: absolute;
                    top: 1px;
                    left: 0;
                }

                &:hover {
                    color: $dnBlue;
                    text-decoration: underline;

                    i {
                        color: $dnBlue;
                    }
                }

                &.phone {
                    padding-left: 20px;

                    i {
                        top: 0px;
                    }
                }

                &.email {
                    padding-left: 26px;
                }
            }
        }

        &--practice {
            margin: 12px 0 16px;

            h4 {
                color: $dnBlue;
                margin: 0 0 7px;
            }

            ul {
                @extend %clearfix;
                @extend %list-unstyled;
                margin: 0 -4px;
            }

            li {
                float: left;
                padding: 0 4px;
                margin: 0 0 8px;

                > a {
                    padding: 3px 9px;
                    display: inline-block;
                    font-size: calc-em(12px);
                    line-height: 16px;
                    background-color: transparent;
                    color: $dnBlue;
                    text-decoration: none;
                    transition: all 0.3s;
                }

                &:hover a {
                    background-color: $dnBlue;
                    color: #fff;
                }
            }
        }

    }
}

@media only screen and (max-width: $bpTablet - 1) {
    .lawyer-item {
        &__text {
            p {
                a {
                    &.email {
                        display: block;
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $bpDesktop) {
    .lawyer-item {
        &__text {
            h3 {
                font-size: calc-em(20px);
                line-height: 26px;
            }

            p,
            >a {
                font-size: calc-em(13px);
                line-height: 19px;
                margin: 0 0 6px;
            }
        }
    }
}

@media print {
    .lawyer-item {
        page-break-inside: avoid;
    }
}
