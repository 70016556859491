// footer-links styles
.site-footer__links {
	float: right;

	li {
		float: left;

		&:last-child a {
			padding-right: 0;

			&:after {
				display: none;
			}
		}
	}

	a {
		@include vertical-middle(32px);
		color: #fff;
		display: inline-block;
		padding-right: 16px;
		text-decoration: none;

		&:after {
			content: '|';
			display: inline-block;
			position: relative;
			vertical-align: middle;
			margin-top: -3px;
			margin-left: 18px;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.site-footer__links {
		float: none;
		line-height: 24px;

		a {
			height: 24px;
		}
	}
}
