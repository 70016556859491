// related-links styles
.related-links, .side-nav {
	background-color: #fff;
	padding: 17px 24px 30px;
	margin: 0 0 35px;

	h2 {
		font-size: calc-em(19px);
		line-height: 45px;
		border-bottom: 1px solid $dnBorder;
		margin: 0;
		color: $dnBlue;
	}

	ul {
		@extend %list-unstyled;
		padding: 20px 0 0;
	}

	li {
		a {
			font-size: calc-em(13px);
			line-height: 16px;
			display: inline-block;
			position: relative;
			padding: 3px 0 20px 34px;
			color: $dnBlue;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			i {
				font-size: calc-em(8px, 13px);
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				padding: 6px 0;
				border-radius: 50%;
				color: #fff;
				background-color: $dnBlue;
				text-align: center;
				transform: scaleX(-1);
			}
		}

		&:last-child > a {
			padding-bottom: 0;
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.related-links, .side-nav {
		padding: 17px 34px 30px;

		h2 {
			font-size: calc-em(23px);
			line-height: 50px;
		}

		li {
			a {
				font-size: calc-em(15px);
				line-height: 20px;
				padding-top: 0;
				color: $dnJoinusBlack;

				&:hover {
					color: $dnBlue;
				}
			}
		}
	}
}

@media print {
	.related-links {
		display: none;
	}
}
