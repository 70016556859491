// home-joinus-item styles
.home-joinus-item {
	a {
		display: inline-block;
		text-decoration: none;
		text-align: center;
		color: $dnJoinusBlack;

		&:hover {
			color: $dnBlue;

			p {
				text-decoration: underline;
			}
		}
	}

	&__icon {
		i {
			font-size: calc-em(50px);
		}
	}

	&__label {
		p {
			display: inline-block;
			max-width: 140px;
			font-size: calc-em(14px);
			line-height: 20px;
			margin: 17px 0 0;
		}
	}
}

@media only screen and (min-width: $bpTablet), print {
	.home-joinus-item {
		display: inline-block;
		vertical-align: top;

		&__icon {
			i {
				font-size: calc-em(70px);
			}
		}

		&__label {
			p {
				font-size: calc-em(17px);
				line-height: 22px;
				margin: 20px 0 0;
			}
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.home-joinus-item {
		float: left;
		width: 50%;
		text-align: center;
		margin-bottom: 37px;

		&:last-child, &:nth-last-child(2) {
			margin: 0;
		}
	}
}
