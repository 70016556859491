// side-nav styles

// Extends related-links.scss

.side-nav {
    padding: 30px 24px;

    h2 {
        margin-bottom: 20px;
        padding: 0 0 13px;
        line-height: 1;
        text-transform: capitalize;
    }

    > ul {
        padding: 0;

        > li {
            padding-bottom: 20px;

            > a {
                padding-bottom: 0;
            }

        }

        ul {
            padding-top: 10px;
            padding-left: 19px;

            a {
                padding-bottom: 10px;
                position: relative;

                &:after {
                    content: '';
                    width: 10px;
                    height: 1px;
                    background-color: $dnBlue;
                    position: absolute;
                    top: 10px;
                    left: 15px;
                }
            }
        }
    }
}

@media only screen and (min-width: $bpDesktop) {
    .side-nav {
        padding: 30px 34px;

        > ul {
            padding: 0;
        }
    }
}

@media print {
    .side-nav {
        display: none;
    }
}
