// form styles
.EditingFormTable,
.mailing-list {
    // max-width: 450px;

    .name-row,
    .company-row,
    .city-postal-row,
    .contact-row {
        @extend %clearfix;
    }

    .title, .country {
        .select-clone-pair {
            margin-bottom: 6px;
        }
    }

    .lawyername input:disabled,
    .lawyername input:not([type]):disabled {
        background-color: rgba(84, 84, 84, 0.6) !important;
    }
}

@media only screen and (min-width: $bpTablet){
    .EditingFormTable,
    .mailing-list {
        max-width: 750px;

        .name-row {
            margin: 0 -7px;

            .title {
                float: left;
                width: 24%;
                padding: 0 7px;
            }

            .fname, .lname {
                float: left;
                width: 38%;
                padding: 0 7px;
            }
        }

        .company-row, .city-postal-row, .contact-row {
            margin: 0 -7px;

            .field-group {
                float: left;
                width: 50%;
                padding: 0 7px;
            }
        }
    }
}

.training-contracts {
    max-width: 750px;
}

.submit-sm {
    padding: 5px 10px;
    border: 1px solid $dnBlue;
    display: inline-block;
    line-height: 16px;
    background-color: transparent;
    color: $dnBlue;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
        background-color: $dnBlue;
        color: #fff;
    }
}

.mailing-list,
.training-contracts {
    margin-top: 50px;
}

.EditingFormTable,
.mailing-list,
.training-contracts {
    width: 100%;
    font-size: calc-em(13px);

    .EditingFormTable {
        font-size: calc-em(13px, 13px);
    }

    h3 {
        font-size: calc-em(18px, 13px);
    }

    h4 {
        font-size: calc-em(16px, 13px);
    }

    .select-clone button {
        font-size: calc-em(13px, 13px);
    }

    .cta,
    .submit {
        font-size: calc-em(14px, 13px) !important;
    }

    .field-group {
        @extend %clearfix;
        margin-bottom: 10px;
    }

    table {
        td {
            vertical-align: middle !important;

            input:not([type="checkbox"]):not([type="radio"]) {
                min-width: 200px !important;
            }

            a {
                color: $dnBlue;
            }
        }
    }

    label,
    input:not([type="file"]):not([type="checkbox"]):not([type="radio"]):not([type="image"]):not([type="submit"]),
    select,
    textarea {
        display: block;
        width: 100%;
        font-size: calc-em(13px, 13px);
        font-weight: 400;
        line-height: 18px;
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
    }

    label {
        margin-bottom: 5px;
    }

    input:not([type=radio]):not([type="file"]):not([type="checkbox"]), select, option, textarea {
        padding: 12px;
        background-color: $dnInputGray;
        margin-bottom: 6px;
        outline: 0;
        border: none;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $dnPlaceholderGray;
            font-weight: 400;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $dnPlaceholderGray;
            font-weight: 400;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $dnPlaceholderGray;
            font-weight: 400;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $dnPlaceholderGray;
            font-weight: 400;
        }
    }

    .radio, .checkbox {
        float: left;
        padding-right: 10px;

        label {
            position: relative;
            padding-left: 20px;
            display: inline;

            input {
                position: absolute;
                top: 2px;
                left: 0;
            }
        }
    }

    .privacy {
        margin: 30px 0;
        font-size: calc-em(14px, 13px);
        line-height: 16px;

        a {
            color: $dnBlue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    table {
        input, label {
            display: inline;
        }
    }
}
