// resource-filters styles
.listing-filter__resource {
    box-shadow: none;
    padding: 30px;
    max-width: 100%;

    #date-from,
    #date-to {
        @extend %clearfix;
    }
}

@media only screen and (min-width: $bpTablet) {
    .listing-filter__resource {
        #practice-sector {
            width: 50%;
            float: left;
            margin: 0;
        }

        .field-group {
            &.search-button {
                position: static;
            }

            button[type="submit"]{
                margin-right: 20px;
            }
        }
    }
}

@media print {
    .listing-filter__resource {
        .search-button {
            display: none;
        }

        #practice-sector {
            margin: 0;
            width: 50%;
        }
    }
}
