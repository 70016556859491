// text-snippet styles
.text-snippet {
	padding-bottom: 22px;
	border-bottom: 1px solid $dnBorder;

	> * {
		display: none;

		&:first-child, &:nth-child(2), &:nth-child(3) {
			display: block;
		}
	}

	.rte {
		margin: 0;
	}

	.read-more-content {
		margin: 0;

		> * {
			margin: 0 0 16px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.read-more {
		display: block;
		background-color: transparent;
		color: $dnBlue;
		line-height: 28px;
		padding: 0 22px 0 0;
		position: relative;
		border: none;
		font-weight: bold;
		margin: 0;
		cursor: pointer;
		outline: 0;

		i {
			position: absolute;
			top: 8px;
			right: 0;
			font-size: calc-em(11px, 13px);
			transform: rotate(-90deg);
			transition: all 0.3s;
		}

		&.less {
			i {
				transform: rotate(-90deg) scaleX(-1);
			}
		}
	}
}

@media print {
	.text-snippet {
		.read-more {
			display: none;
		}

		.read-more-content {
			display: block !important;
			visibility: visible !important;
		}
	}
}

