// home-connect styles
.home-connect {
	background-color: $dnSectionGray;
	padding: 25px 0;

	.container {
		text-align: center;
	}

	h2 {
		color: $dnTitleBlack;
		font-size: calc-em(22px);
		margin: 0;
	}

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
		display: inline-block;
	}

	li {
		display: block;
		padding: 20px 0 0;
	}

	a {
		color: $dnTextBlack;
		text-decoration: none;
		font-size: calc-em(14px);
		position: relative;
		padding-left: 35px;

		&:hover {
			color: $dnBlue;
			text-decoration: underline;
		}
	}

	i {
		position: absolute;
		top: 1px;
		left: 0;
	}
}

@media only screen and (min-width: $bpTablet), print {
	.home-connect {
		padding: 45px 0;
		margin-bottom: 50px;

		h2 {
			font-size: calc-em(36px);
			margin: 0 0 30px;
		}

		li {
			float: left;
			padding: 0 50px;
		}

		a {
			font-size: calc-em(19px);
			padding-left: 55px;
		}
	}
}
