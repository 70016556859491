// expertise-contacts styles
.expertise-contacts {
	position: relative;

	&__header {
		h2 {
			font-size: calc-em(19px);
			line-height: 1;
			color: $dnBlue;
			margin: 0 0 13px;
		}
	}

	&__content {
		@extend %clearfix;
		position: relative;
		margin: 0 -18px;
		padding: 56px 9px 0;
		transition: all 0.5s;

		.paging {
			font-size: calc-em(13px);
			line-height: 26px;
			margin: 0 0 5px;
			padding: 0 18px;
			text-align: right;
			// position: absolute;
			color: $dnTestimonialArrow;

			p {
				margin: 0;
			}
		}

		.view-all {
			position: absolute;
			top: 0;
			right: 9px;
			font-size: calc-em(13px) !important;
		}
	}

	.slick-slide:focus,
	.slick-slide a:focus {
		outline: 0;
	}

	.slick-track {
		margin-left: 0;
	}

	.slick-arrow {
		border: none;
		background-color: transparent;
		position: absolute;
		top: -88px;
		right: 18px;
		cursor: pointer;
		outline: 0;
		padding: 0;

		i {
			font-size: calc-em(17px);
			color: $dnTestimonialArrow;
		}

		&:hover {
			i {
				color: $dnBlue;
			}
		}

		&.slick-next {
			i {
				display: inline-block;
				transform: scaleX(-1);
			}
		}

		&.slick-prev {
			right: 58px;
		}
	}

	&.unslicked {
		.expertise-contacts__content {
			padding: 0 9px;
		}

		.expertise-contacts-item {
			margin-bottom: 20px;
		}

		.view-all {
			display: none !important;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.expertise-contacts {
		&__header {
			margin: 0 0 25px;
		}

		&__content {
			position: static;
			margin: 0;
			padding: 0 30px 20px;

			.paging {
				position: absolute;
				top: 2px;
				right: 0;
				margin: 0;
				padding: 0;
			}

			.view-all {
				position: absolute;
				top: -10px;
				right: 0;
			}
		}

		.slick-arrow {
			position: absolute;
			top: 50%;

			i {
				font-size: calc-em(17px);
			}

			&.slick-prev {
				left: 0;
				right: auto;
				transform: translate(-100%, -50%);
			}

			&.slick-next {
				right: 0;
				transform: translate(100%, -50%);
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.expertise-contacts {
		&__header {
			margin: 0;

			h2 {
				font-size: calc-em(28px);
				line-height: 1;
				margin: 0 0 25px;
			}
		}

		&__content {
			.view-all {
				top: -6px;
			}
		}

		.slick-arrow {
			i {
				font-size: calc-em(21px);
			}
		}
	}
}

@media print {
	.expertise-contacts {
		.view-all {
			display: none;
		}
	}
}
