// tab styles
.tab {
	&__tabs {
		margin-bottom: 20px;
		position: relative;

		ul {
			@extend %list-unstyled;
		}
	}

	&__category {
		@extend %clearfix;
		display: none;

		&.active {
			// display: block;
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1){
	.tab {
		&__tabs {
			.selected-option {
				color: $dnBlue;
				display: inline-block;
				width: 100%;
				font-size: calc-em(14px);
				line-height: 1;
				padding: 13px 0;
				text-align: center;
				position: relative;
				background-color: $dnInputGray;

				i {
					position: absolute;
					top: 50%;
					right: 12px;
					font-size: calc-em(5px);
					transform: translate(0, -50%);
					z-index: 2;
					transition: all 0.3s;
				}

				&.active {
					i {
						transform: translate(0, -50%) scaleY(-1);
					}
				}
			}

			ul {
				position: absolute;
				top: 0;
				left: 20px;
				right: 20px;
				width: calc(100% - 40px);
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in;
				z-index: 2;

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			li {
				width: 100%;
				display: inline-block;
				text-align: center;
				background-color: $dnInputGray;
				color: $dnBlue;
			}

			a {
				display: inline-block;
				color: $dnBlue;
				text-decoration: none;
				width: 100%;
				font-size: calc-em(14px);
				line-height: 1;
				padding: 13px 0;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop), print {
	.tab {
		&__tabs {
			margin-bottom: 35px;

			.container {
				border-bottom: 1px solid $dnBorder;
			}

			ul {
				// @include justify;
				// margin-bottom: -19px;
				margin: 0 -22px;
			}

			li {
				display: inline-block;
				padding: 0 30px;

				&:hover, &.active {
					a {
						color: $dnBlue;
						border-bottom: 3px solid $dnBlue;
					}
				}

				&.active {
					a {
						font-weight: bold;
					}
				}

				&:last-child {
					padding-right: 0;

					&:after {
						display: none;
					}
				}
			}

			a {
				color: $dnTabs;
				display: inline-block;
				padding: 2px 12px;
				font-size: calc-em(16px);
				line-height: 50px;
				text-decoration: none;
				border-bottom: 3px solid transparent;
			}
		}
	}
}

@media print {
	.profile-content {
		margin-top: 30px;
	}

	.tab {
		li {
			padding: 0;
		}
	}
}
